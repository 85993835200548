<template>
    <footer
            id="footer"
            class="footer mt-3"
            role="contentinfo"
            itemprop="hasPart"
            itemscope
            itemtype="https://schema.org/WPFooter">
        <b-container
                class="p-0"
                fluid="md">
            <h3>
                Contáctanos
            </h3>

            <b-row
                    id="estsuelcon"
                    itemid="#estsuelcon"
                    itemscope
                    itemtype="https://schema.org/LocalBusiness">

                <!-- schema.org -->
                <meta
                        itemprop="name"
                        content="ESTSUELCON CÍA. LTDA."/>
                <meta
                        itemprop="legalName"
                        content="ESTSUELCON CÍA. LTDA."/>
                <meta
                        itemprop="logo"
                        :content="require('@/assets/logos/estsuelcon-og.png')"/>
                <meta
                        itemprop="image"
                        :content="require('@/assets/images/contacto_16-9.jpg')"/>

                <b-col
                        cols="0"
                        lg="1"/>
                <b-col
                        cols="12"
                        sm="7"
                        lg="6">
                    <div
                            itemprop="hasMap"
                            itemscope
                            itemtype="http://schema.org/Map">
                        <meta
                                itemprop="name"
                                content="Mapa de la sede en Loja"/>
                        <meta
                                itemprop="url"
                                content="https://goo.gl/maps/JiuvaKVEPuL3ymqB9"/>
                    </div>

                    <!-- Phone number -->
                    <b-link
                            class="link slide-left"
                            :href="`tel:${call(phone)}`"
                            aria-label="Llámanos al teléfono fijo"
                            title="Llámanos al teléfono fijo"
                            itemprop="telephone"
                            :content="call(phone)"
                            v-in-viewport.once>
                        <b-icon-house-door/>
                        {{ phone }}
                    </b-link>

                    <!-- Mobile number -->
                    <b-link
                            class="link slide-left"
                            :href="whatsapp"
                            rel="nofollow"
                            aria-label="Escríbenos por Whatsapp"
                            title="Escríbenos por Whatsapp"
                            itemprop="telephone"
                            :content="mobile"
                            v-in-viewport.once>
                        <whatsapp
                                style="fill: currentColor; width: 1rem; height: 1rem; margin: 0 .5rem"/>
                        {{ mobile }}
                    </b-link>

                    <!-- Address main -->
                    <b-link
                            class="link slide-left"
                            href="https://goo.gl/maps/JiuvaKVEPuL3ymqB9"
                            target="_blank"
                            aria-label="Mapa de la sede en Loja"
                            v-in-viewport.once>
                        <b-icon-geo-alt/>
                        <div
                                itemprop="address"
                                itemscope
                                itemtype="http://schema.org/PostalAddress">
                            <meta
                                    itemprop="addressCountry"
                                    content="ec"/>

                            <span
                                    class="text-nowrap"
                                    itemprop="name">
                                Sede central
                            </span><br/>

                            <span
                                    class="text-nowrap"
                                    itemprop="streetAddress">
                                Detroit entre New York y California
                            </span><br/>

                            <span
                                    class="text-nowrap"
                                    itemprop="addressLocality">
                                Parque Industrial de Loja
                            </span>

                            <meta
                                    itemprop="addressRegion"
                                    content="Loja"/>
                        </div>
                    </b-link>

                    <!-- Address branch -->
                    <b-link
                            class="link slide-left"
                            href="https://goo.gl/maps/6RUtLxuRY1j8U1D38"
                            target="_blank"
                            aria-label="Mapa de la surcursal en Zamora Chinchipe"
                            v-in-viewport.once>
                        <b-icon-geo-alt/>
                        <div
                                itemprop="address"
                                itemscope
                                itemtype="http://schema.org/PostalAddress">
                            <meta
                                    itemprop="addressCountry"
                                    content="ec"/>

                            <span
                                    class="text-nowrap"
                                    itemprop="name">
                                Sucursal
                            </span><br/>

                            <span
                                    class="text-nowrap"
                                    itemprop="streetAddress">
                                24 de Mayo y Amazonas
                            </span><br/>

                            <span
                                    class="text-nowrap"
                                    itemprop="addressLocality">
                                Los Encuentros</span>,

                            <span
                                    class="text-nowrap"
                                    itemprop="addressRegion">
                                Zamora Chinchipe
                            </span>
                        </div>
                    </b-link>

                    <!-- Email -->
                    <b-link
                            class="link slide-left"
                            :href="`mailto:${email}?subject=Consulta%20a%20Estsuelcon&body=Estimados%0A%0A`"
                            aria-label="Estsuelcon correo electrónico"
                            v-in-viewport.once>
                        <b-icon-envelope/>
                        <span
                                itemprop="email">
                            {{ email }}
                        </span>
                    </b-link>

                    <meta itemprop="priceRange" content="Not Applicable"/>
                </b-col>

                <b-col
                        class="d-sm-none"
                        cols="1"/>

                <b-col
                        itemid="#estsuelcon"
                        class="fade-in p-0"
                        style="transition-delay: .3s"
                        cols="10"
                        sm="5"
                        lg="3"
                        v-in-viewport.once>

                    <!-- Logo -->
                    <b-img-lazy
                            alt="Estsuelcon"
                            class="d-block ml-auto mr-auto"
                            style="width: 12.15rem"
                            :src="require('@/assets/logos/estsuelcon-white.png')"
                            itemprop="logo"/>

                    <!-- Social networks -->
                    <div
                            class="d-flex justify-content-around align-items-center ml-auto mr-auto mt-3">
                        <h5
                                class="m-0">
                            Síguenos
                        </h5>

                        <link itemprop="url" :href="baseUrl" rel="nofollow">

                        <!-- Facebook -->
                        <b-link
                                :href="facebook"
                                target="_blank"
                                aria-label="Nuestra cuenta de Facebook"
                                title="Nuestra cuenta de Facebook"
                                itemprop="sameAs">
                            <facebook
                                    class="svg"
                                    alt="Estsuelcon Facebook account"/>
                        </b-link>

                        <!-- Instagram -->
                        <b-link
                                :href="instagram"
                                target="_blank"
                                aria-label="Nuestra cuenta de Instagram"
                                title="Nuestra cuenta de Instagram"
                                itemprop="sameAs">
                            <instagram
                                    class="svg"
                                    alt="Estsuelcon Instagram account"/>
                        </b-link>

                        <!-- Twitter -->
                        <b-link
                                :href="twitter"
                                target="_blank"
                                aria-label="Nuestra cuenta de Twitter"
                                title="Nuestra cuenta de Twitter"
                                itemprop="sameAs">
                            <twitter
                                    class="svg"
                                    alt="Estsuelcon Twitter account"/>
                        </b-link>
                    </div>

                    <!-- Rating -->
                    <div
                            v-if="ratingValue"
                            itemprop="aggregateRating"
                            itemscope
                            itemtype="http://schema.org/AggregateRating">
                        <b-link
                                class="link"
                                href="https://www.facebook.com/estsuelcon/reviews/"
                                target="_blank"
                                aria-label="Nuestras evaluaciones en Facebook"
                                title="Nuestras evaluaciones en Facebook"
                                itemprop="sameAs"
                                v-in-viewport.once>
                        <span
                                class="seo">
                            Nuestras evaluaciones en Facebook
                        </span>

                            <b-form-rating
                                    class="b-rating text-center text-white ml-auto mr-auto mt-3"
                                    :value="ratingValue"
                                    variant="white"
                                    size="sm"
                                    inline
                                    readonly
                                    show-value
                                    show-value-max
                                    itemprop="ratingValue"
                                    :content="ratingValue"/>

                            <meta
                                    itemprop="ratingCount"
                                    :content="ratingCount"/>
                        </b-link>
                    </div>

                    <!-- Webmail interno -->
                    <b-link
                            class="link mt-3 justify-content-center"
                            href="https://estsuelcon.ec/webmail"
                            rel="nofollow"
                            target="_blank">
                        <b-icon-envelope/>
                        Webmail interno
                    </b-link>
                </b-col>
            </b-row>

            <!-- Copyright -->
            <b-row
                    class="mt-3">
                <b-col
                        class="copyright text-center"
                        rows="12">
                    <span
                            style="white-space: nowrap">
                        &copy;
                        &nbsp;
                        <span
                                itemprop="copyrightYear">
                            {{ new Date().getFullYear() }}
                        </span>
                        &nbsp;
                        <link
                                itemprop="copyrightHolder"
                                href="#estsuelcon"/>

                        ESTSUELCON CÍA. LTDA.
                    </span>
                    &nbsp;
                    <span
                            style="white-space: nowrap">
                        Todos los derechos reservados.
                    </span>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
  import whatsapp from '@/assets/icons/whatsapp.svg'
  import facebook from '@/assets/icons/facebook.svg'
  import instagram from '@/assets/icons/instagram.svg'
  import twitter from '@/assets/icons/twitter.svg'

  export default {
    name: 'Footer',

    components: { whatsapp, facebook, instagram, twitter },

    data () {
      return {
        phone: process.env.VUE_APP_ESTSUELCON_PHONE,
        mobile: process.env.VUE_APP_ESTSUELCON_MOBILE,
        email: process.env.VUE_APP_ESTSUELCON_EMAIL,
        whatsapp: 'https://api.whatsapp.com/send?phone=' + this.call(process.env.VUE_APP_ESTSUELCON_MOBILE).replace('+', ''),
        facebook: process.env.VUE_APP_ESTSUELCON_FACEBOOK,
        instagram: process.env.VUE_APP_ESTSUELCON_INSTAGRAM,
        twitter: process.env.VUE_APP_ESTSUELCON_TWITTER,
        baseUrl: process.env.VUE_APP_ESTSUELCON_URL,
        ratingValue: sessionStorage.ratingValue || '',
        ratingCount: sessionStorage.ratingCount || ''
      }
    },

    mounted() {

      if (!this.ratingValue || !this.ratingCount) {
        fetch('/rating.json')
          .then(response => response.json())
          .then(data => {
            this.ratingValue = data.ratingValue
            sessionStorage.ratingValue = this.ratingValue
            this.ratingCount = Math.max(1, data.ratingCount)
            sessionStorage.ratingCount = this.ratingCount
          })
      }
    },

    methods: {
      /**
       * Cleans the phone number for links
       * @param number String
       */
      call (number) {
        // Remove invalid characters
        number = number.replace(/[^+0-9]/g, '')

        // Remove starting zero(s)
        number = number.replace(/^0+/, '')

        // Add international prefix
        if (!number.startsWith(process.env.VUE_APP_ESTSUELCON_INTERNATIONAL)) {
          number = process.env.VUE_APP_ESTSUELCON_INTERNATIONAL + number
        }

        return number
      }
    }
  }
</script>

<style scoped>
    .footer {
        padding: 1.5rem;
        background-color: var(--var-estsuelcon-primary);
    }

    .b-icon {
        margin: 0 .5rem;
    }

    .footer,
    .link {
        color: white
    }

    .link {
        display: flex;
        align-items: center;
        padding: .35rem .5rem;
        transition-delay: .05s
    }

    .link:hover,
    .svg:hover {
        color: white;
        text-decoration: none;
        filter: drop-shadow(0 0 2px currentColor)
    }

    .svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: white
    }

    .b-rating {
        background-color: transparent !important;
        width: 8rem
    }

    .seo {
        font-size: 0;
    }

    .copyright {
        font-size: .75rem
    }
</style>