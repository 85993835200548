// Routes
module.exports = [
  {
    path: '/',
    component: () => import('@/components/pages/Home'),
    meta: {
      title: process.env.VUE_APP_ESTSUELCON_META_TITLE,
      description: process.env.VUE_APP_ESTSUELCON_META_DESCRIPTION,
      sitemap: {
        priority: 1
      }
    }
  },
  {
    path: '/nosotros/',
    component: () => import('@/components/pages/About'),
    meta: {
      title: 'Estsuelcon | Nosotros',
      description: 'Misión: Ser líder en consultoría civil, geología y construcción; Visión: Integrar ciencia y la experiencia, transmitir conocimiento; Equipo: Proveer experiencia y calidad.',
      sitemap: {
        priority: 1
      }
    }
  },
  {
    path: '/laboratorio/',
    component: () => import('@/components/pages/services/Lab'),
    meta: {
      title: 'Estsuelcon | Laboratorio',
      description: 'Estsuelcon es líder en ensayos estándares y especiales en suelos, ensayos de mecánica de rocas y ensayos de agregados.'
    }
  },
  {
    path: '/geofisica/',
    component: () => import('@/components/pages/services/Geophysics'),
    meta: {
      title: 'Estsuelcon | Geofísica sísmica de refracción y resistividad eléctrica',
      description: 'Estsuelcon es líder en métodos eléctricos y sísmicos.'
    }
  },
  {
    path: '/perforaciones/',
    component: () => import('@/components/pages/services/Drills'),
    meta: {
      title: 'Estsuelcon | Perforaciones destructivas y diamantinas',
      description: 'Estsuelcon es líder en sondajes geotécnicos y ensayos de permeabilidad.'
    }
  },
  {
    path: '/consultoria/',
    component: () => import('@/components/pages/services/Consultancy'),
    meta: {
      title: 'Estsuelcon | Consultoría y construcción',
      description: 'Estsuelcon es líder en consultoría técnica y estudios para que se realicen sus proyectos con éxito.'
    }
  },
  {
    path: '/control-de-calidad/',
    component: () => import('@/components/pages/services/QA'),
    meta: {
      title: 'Estsuelcon | Control de calidad',
      description: 'Estsuelcon es líder en ensayos de mecánica de suelos y materiales y supervisión en la contrucción.'
    }
  },
  {
    path: '/proyectos/',
    component: () => import('@/components/pages/Projects'),
    meta: {
      title: 'Estsuelcon | Proyectos',
      description: 'Referencias de nuestros proyectos que se realizaron en tiempo, con calidad y a la completa satisfacción de nuestros clientes.'
    }
  },
  {
    path: '/contacto/',
    component: () => import('@/components/pages/Contact'),
    meta: {
      title: 'Estsuelcon | Control de calidad',
      description: 'El equipo de soporte de Estsuelcon espera su consulta y responde puntual con información de calidad y competencia.'
    }
  }
]
