<template>
    <b-navbar
            id="navigation"
            class="navbar"
            toggleable="md"
            role="navigation"
            itemprop="hasPart"
            itemscope
            itemtype="http://schema.org/SiteNavigationElement">
        <b-container>
            <b-navbar-brand
                    to="/">
                <b-img
                        alt="Estsuelcon"
                        class="navbar-brand-image"
                        :src="require('@/assets/logos/estsuelcon.png')"
                        itemprop="image"/>
            </b-navbar-brand>

            <b-navbar-toggle
                    id="navbar-toggler"
                    target="nav-collapse"></b-navbar-toggle>

            <b-collapse
                    @shown="hideListener"
                    id="nav-collapse"
                    style="max-width: calc(100vw - 2rem)"
                    is-nav>
                <b-navbar-nav
                        class="navbar-nav pt-1 pl-3 pl-sm-3 pl-md-0">
                    <b-nav-item
                            to="/nosotros"
                            aria-label="Sobre nuestra empresa">
                        <link
                                itemprop="url"
                                href="/nosotros"/>
                        <span
                                itemprop="name">
                            Nosotros
                        </span>
                    </b-nav-item>

                    <b-nav-item-dropdown
                            text="Servicios">
                        <b-dropdown-item
                                to="/laboratorio"
                                aria-label="Estudios de laboratorio"
                                itemprop="url">
                            <span
                                    itemprop="name">
                                Laboratorio
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                to="/geofisica"
                                aria-label="Geofísica"
                                itemprop="url">
                            <span
                                    itemprop="name">
                                Geofísica
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                to="/perforaciones"
                                aria-label="Perforaciones destructivas y diamantinas"
                                itemprop="url">
                            <span
                                    itemprop="name">
                                Perforaciones
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                to="/consultoria"
                                aria-label="Consultoría y construcción"
                                itemprop="url">
                            <span
                                    itemprop="name">
                                Consultoría
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                                to="/control-de-calidad"
                                aria-label="Control de calidad"
                                itemprop="url">
                            <span
                                    itemprop="name">
                                Control de calidad
                            </span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>

            <b-button
                    class="contact btn-estsuelcon"
                    to="/contacto"
                    rel="help"
                    aria-label="Formulario de contacto"
                    itemprop="url"
                    variant="transparent">
                <b-icon-envelope
                        alt="Formulario de contacto"/>

                <span
                        class="d-none d-sm-inline"
                        itemprop="name">
                    Contáctanos
                </span>
            </b-button>
        </b-container>
    </b-navbar>
</template>

<script>
  export default {
    name: 'Navigation',

    methods: {
      hideListener () {
        document.body.addEventListener('click', (e) => {
          if (!e.target.closest('#navbar-toggler, #nav-collapse')) {
            document.getElementById('navbar-toggler').click()
          }
        }, {
          once: true,
          bubble: true
        })
      }
    }
  }
</script>

<style scoped>
    #navigation {
        height: 3.6rem;
        max-height: 3.6rem
    }

    .navbar {
        background: rgba(255, 255, 255, .85);
        font-size: 1.75rem;
        z-index: 99
    }

    .nav-item {
        padding: 0 .5rem
    }

    .navbar-brand {
        padding: 0
    }

    .navbar-brand-image {
        height: 2.6rem;
        transform: scale(1.2);
    }

    .navbar-nav {
        overflow-x: hidden;
    }

    .dropdown >>> .dropdown-toggle::after {
        vertical-align: .2em;
    }

    .dropdown >>> .dropdown-menu {
        font-size: 1.75rem;
        background: rgba(255, 255, 255, .85);
    }

    .dropdown >>> .dropdown-item,
    .dropdown >>> .dropdown-item.active,
    .dropdown >>> .dropdown-item:active {
        color: rgba(0, 0, 0, 0.5);
        background: none
    }

    .dropdown >>> .router-link-active,
    .dropdown >>> .dropdown-item:hover {
        color: rgba(0, 0, 0, 0.7)
    }

    .dropdown >>> .dropdown-item:hover {
        background: white
    }

    @media (min-width: 768px) {
        .navbar-nav {
            overflow-x: visible;
        }

        .navbar-collapse.collapse {
            opacity: 1;
        }

        .dropdown >>> .dropdown-menu {
            opacity: 0;
            display: block;
            visibility: collapse;
            transform: translateY(-15px);
            transition: visibility 0s, all .3s ease
        }

        .dropdown >>> .dropdown-menu.show {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
        }
    }

    @media (max-width: 767px) {
        .navbar-collapse {
            position: absolute;
            top: 3.4rem;
            border: solid 1px #ccc;
            border-radius: 12px;
            background: rgba(255, 255, 255, .9);
            opacity: 0;
            transition: all .3s ease;
            z-index: 999;
        }

        .navbar-collapse.show {
            opacity: 1;
        }

        .dropdown >>> .dropdown-toggle {
            display: none
        }

        .dropdown >>> .dropdown-menu {
            display: block;
            margin-top: -.25rem;
            padding-top: 0;
            border: none;
            background: transparent
        }

        .navbar .navbar-nav >>> .nav-link,
        .dropdown >>> .dropdown-menu .dropdown-item {
            padding-top: .125rem;
            padding-bottom: .125rem;
            color: rgba(0, 0, 0, 0.6)
        }
    }
</style>