import Vue from 'vue'

import {
    AspectPlugin,
    ButtonPlugin,
    CarouselPlugin,
    FormInputPlugin,
    FormPlugin,
    FormRatingPlugin,
    FormTextareaPlugin,
    IconsPlugin,
    ImagePlugin,
    InputGroupPlugin,
    LayoutPlugin,
    LinkPlugin,
    NavbarPlugin,
    OverlayPlugin,
    ToastPlugin,
    VBVisiblePlugin
} from 'bootstrap-vue'

import VueRouter from 'vue-router'
import routes from '@/routes'
import App from './App.vue'
import inViewportDirective from 'vue-in-viewport-directive'
import '@/assets/scss/css/estsuelcon.css'

Vue.directive('in-viewport', inViewportDirective)

Vue.use(AspectPlugin)
Vue.use(ButtonPlugin)
Vue.use(CarouselPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormPlugin)
Vue.use(FormRatingPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(IconsPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(NavbarPlugin)
Vue.use(OverlayPlugin)
Vue.use(ToastPlugin)
Vue.use(VBVisiblePlugin)

Vue.use(VueRouter)

Vue.config.productionTip = false

// Router
const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.publicPath
})

// Reset scroll position on route switch
router.beforeEach((to, from, next) => {
    document.getElementById('app').scrollTo(0, 0)
    next()
})

// Set meta data after route switch
router.afterEach((to) => {
    Vue.nextTick(() => {
        // Set title
        const title = to.meta && to.meta.title ? to.meta.title : process.env.VUE_APP_ESTSUELCON_META_TITLE
        document.title = title
        document.head.querySelectorAll('[property="og:title"], [name="twitter:title"]').forEach((el) => {
            el.setAttribute('content', title)
        })

        // Set description
        const description = to.meta && to.meta.description ? to.meta.description : process.env.VUE_APP_ESTSUELCON_META_DESCRIPTION
        document.head.querySelectorAll('[name="description"], [property="og:description"], [name="twitter:description"]').forEach((el) => {
            el.setAttribute('content', description)
        })

        // Set link
        document.head.querySelector('[rel="canonical"]').setAttribute('href', to.fullPath)
        document.head.querySelector('[property="og:url"]').setAttribute('content', process.env.VUE_APP_ESTSUELCON_URL + to.fullPath)
        document.body.querySelector('#app > [itemprop="url"]').setAttribute('href', process.env.VUE_APP_ESTSUELCON_URL + to.fullPath)
    })
})

new Vue({
    render: h => h(App),

    router
}).$mount('#app')
