<template>
    <vue100vh
            id="app"
            :style="{ 'overflow-y': $route.path && $route.path !== '/' ? 'auto' : 'hidden' }"
            itemprop="isPartOf"
            itemscope
            itemtype="https://schema.org/WebSite">

        <link itemprop="url" :href="baseUrl" rel="nofollow">

        <overlay
                v-if="$route.path && $route.path === '/' || (init = false)"
                :show="init"
                @init="init = false">
        </overlay>

        <div
                v-if="false"
                class="ratio-info"
                v-show="!init">
            <div class="ratio-916">
                9:16
            </div>
            <div class="ratio-34">
                3:4
            </div>
            <div class="ratio-43">
                4:3
            </div>
            <div class="ratio-169">
                16:9
            </div>
        </div>

        <header
                itemprop="hasPart"
                itemscope
                itemtype="http://schema.org/WPHeader">
            <navigation
                    class="position-absolute w-100"
                    v-show="!init">
            </navigation>
        </header>

        <main
                id="main"
                role="main"
                class="overflow-hidden"
                :class="{ 'h-100': $route.path === '/' }"
                v-show="!init"
                itemprop="mainEntity"
                itemscope
                itemtype="http://schema.org/CreativeWork">
            <router-view
                    class="slide-top"
                    v-in-viewport/>
        </main>

        <footer
                v-show="!init && $route.path && $route.path !== '/'"
                is="foot">
        </footer>
    </vue100vh>
</template>

<script>
  // iOS fix
  import Vue100vh from 'vue-div-100vh'

  // Components
  import Overlay from '@/components/elements/Overlay'
  import Navigation from '@/components/elements/Navigation'
  import Foot from '@/components/elements/Foot'

  export default {
    components: { Vue100vh, Overlay, Navigation, Foot },

    data () {
      return {
        init: true,
        baseUrl: process.env.VUE_APP_ESTSUELCON_URL
      }
    }
  }
</script>

<style>
    html {
        font-size: 14px
    }

    @media (min-width: 576px) {
        html {
            font-size: 16px
        }
    }

    @media (min-width: 1280px) {
        html {
            font-size: 18px
        }
    }

    body {
        overflow: hidden;
        font-family: 'Montserrat', sans-serif
    }

    #app {
        width: 100vw;
        overflow-x: hidden;
    }

    #main {
        padding-top: 3.6rem;
    }

    #main > div {
        height: 100%;
        overflow: hidden
    }

    .btn.btn-estsuelcon {
        background-color: rgba(255, 255, 255, .7);
        border-color: var(--var-estsuelcon-primary);
        color: var(--var-estsuelcon-primary)
    }

    .btn.btn-estsuelcon:active,
    .btn.btn-estsuelcon.active,
    .btn.btn-estsuelcon.router-link-active,
    .btn.btn-estsuelcon:hover {
        background-color: var(--var-estsuelcon-primary);
        color: white
    }

    :focus {
      outline: none
    }
</style>
