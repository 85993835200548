<template>
    <b-overlay
            v-if="display"
            :show="show"
            class="position-absolute w-100 h-100"
            opacity="1"
            variant="transparent"
            @hidden="display = false">
        <template v-slot:overlay>
            <transition
                    @enter="enter"
                    appear
                    name="overlay-image-grow">
                <b-img
                        :srcset="require('../../assets/logos/estsuelcon.png')"
                        alt="Estsuelcon"
                        class="overlay-image">
                </b-img>
            </transition>
        </template>

        <slot></slot>
    </b-overlay>
</template>

<script>
  export default {
    name: 'Overlay',

    props: {
      show: Boolean
    },

    data() {
      return {
        display: true
      }
    },

    methods: {
      enter: function (el, done) {
        done()
        setTimeout(() => {
          this.$emit('init')
        }, 750)
      }
    }
  }
</script>

<style scoped>
    .b-overlay-wrap >>> .b-overlay {
        background: radial-gradient(circle farthest-corner, #fff, #444);
        background-repeat: no-repeat;
        transition: opacity .5s ease-in
    }

    .b-overlay-wrap .overlay-image {
        width: 6rem
    }

    .b-overlay-wrap .overlay-image-grow-enter-to {
        transform: scale(1.25);
        transition: transform 1.25s ease
    }
</style>